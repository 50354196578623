<template>
<div class='staff' :class='{ disabled: staff.closed || (!staff.clinician && !staff.manager), working }'>
	<div class='info' @click='e=>contextMenu=e' @contextmenu='e=>contextMenu=e'>
		<transition name='fade'>
			<div v-if='working' class='loadingWrapper'><div>
				<Loading />
			</div></div>			
		</transition>
		<div>
			<div>
				<div class='name'><span>{{staff.lastname}}, {{staff.firstname}}</span></div>
				<div class='email'>{{staff.email}}</div>
			</div>
			<div>
				<div class='lastLogin'>{{lastLogin}}</div>
				<div v-if='staff.manager' class='manager'>MANAGER</div>
			</div>
		</div>
	</div>

	<div class='permissions'>

		<div class='teamPermissions'>

			<template v-if="staff.clinician">

				<template v-if='staff.teams && staff.teams.length'>
					<div class='team' v-for='(staffTeam, index) in staff.teams' :key='staff.id+"_"+staffTeam.teamId' :class="{ locked: staffTeam.locked }">

						<div class='teamTitle'>
							<div>
								{{staffTeam.title}}
							</div>		
							<svg v-if='$store.state.teams.teams[staffTeam.teamId].locked' class='locked smallIcon'>
								<use xlink:href='#lock' />
							</svg>
						</div>

						<TernaryToggle :value='staffTeam.teamExercises' @toggle='v=>toggle("teamExercises", v, staffTeam.teamId)' />

						<TernaryToggle :value='staffTeam.teamTemplates' @toggle='v=>toggle("teamTemplates", v, staffTeam.teamId)' />

						<TernaryToggle :value='staffTeam.teamEducation' @toggle='v=>toggle("teamEducation", v, staffTeam.teamId)' />

						<TernaryToggle :value='staffTeam.teamOutcomes' @toggle='v=>toggle("teamOutcomes", v, staffTeam.teamId)' />

						<div >
							<DraggableSwitch
								:value='staffTeam.teamClients'
								:clickable='true'
								@input='v=>toggle("teamClients", v, staffTeam.teamId)'
							/>
						</div>

						<div >
							<DraggableSwitch
								:value='staffTeam.teamSwap'
								:clickable='true'
								@input='v=>toggle("teamSwap", v, staffTeam.teamId)'
							/>
						</div>

					</div>
				</template>
				<template v-else>
						<div class='team' @click='e=>contextMenu=e'>
							<span class='noTeams'>No Teams</span>
						</div>
				</template>

			</template>

		</div>

		<div class='userPermissions' v-if="staff.clinician" :class="{ locked: staff.teams && staff.teams.some(t=>$store.state.teams.teams[t.teamId].locked) }">


				<div >
					<DraggableSwitch
						:value='staff.allowPHI'
						:clickable='true'
						@input='v=>toggle("allowPHI", v)'
					/>
				</div>

				<div >
					<DraggableSwitch
						:value='staff.secondaryAuth'
						:clickable='true'
						@input='v=>toggle("secondaryAuth", v)'
					/>
				</div>

				<TernaryToggle :value='staff.orgExercises' @toggle='v=>toggle("orgExercises", v)' />

				<TernaryToggle :value='staff.orgTemplates' @toggle='v=>toggle("orgTemplates", v)' />

				<TernaryToggle :value='staff.orgEducation' @toggle='v=>toggle("orgEducation", v)' />

				<TernaryToggle :value='staff.orgOutcomes' @toggle='v=>toggle("orgOutcomes", v)' />


		</div>

	</div>

	<ContextMenu 
		v-if='contextMenu' 
		:e='contextMenu'
		@close='contextMenu=false'
	>
		<div class='iconTextButton neutral nameLabel'>
			<svg class='smallIcon'>
				<use xlink:href='#clients' />
			</svg>				
			<span class='label'>{{staff.lastname}}, {{staff.firstname}}</span>
		</div>

		<template v-if="staff.clinician">

			<div 
				v-if='!staff.closed'
				class='textButton neutral'
				@click='save({ closed: true })'
			>
				<span class='label'>
					<template v-if="!staff.manager">Turn Off</template>
					<template v-else>Revoke Clinician Access</template>
				</span>
			</div>
			<div 
				v-else
				class='textButton neutral'
				@click='save({ closed: false })'
			>
				<span class='label'>Grant Clinician Access</span>
			</div>

		</template>
		<template v-else>

			<div class='textButton neutral' @click='save({ clinician: true })'>
				<span class='label'>Grant Clinician Access</span>
			</div>
		
		</template>

		<template v-if='staff.id!==$store.state.profile.user.id'>
			<div 
				v-if='!staff.manager'
				class='textButton neutral'
				@click='save({ manager: true })'
			>
				<span class='label'>Grant Manager Access</span>
			</div>		

			<div 
				v-else
				class='textButton neutral'
				@click='save({ manager: false })'
			>
				<span class='label'>Revoke Manager Access</span>
			</div>	
		</template>	

		<template v-if="staff.clinician">
			<div class='changeTeamWrapper' :class='{ disabled: !joinTeams.length || (staff.teams && staff.teams.length>=3) || staff.closed }'>	
				<select class='changeTeam' @click.stop @change='joinTeam'>
					<option selected disabled>Join Team...</option>
					<option v-for='(team, i) in joinTeams' :key='i' :value='team.id'>{{team.title}}</option>
				</select>	
			</div>	

			<div class='changeTeamWrapper' :class='{ disabled: !leaveTeams.length  || staff.closed }'>	
				<select class='changeTeam' @click.stop @change='leaveTeam'>
					<option selected disabled>Leave Team...</option>
					<option v-for='(team, i) in leaveTeams' :key='i' :value='team.teamId'>{{team.title}}</option>
				</select>	
			</div>
		</template>
	</ContextMenu>


</div>
</template>

<script>
import { dateTime } from '@/components/common/mixins/dateTime'
import { staffApi } from '@/services/api/modules/manager/staff'
import { smallModalMixin } from '@/components/common/mixins/modal'
import DraggableSwitch from '@/components/common/DraggableSwitch'
import ContextMenu from '@/components/common/ContextMenu'
import Loading from '@/components/common/Loading'
import AlertModal from '@/components/common/modals/Alert'
import TernaryToggle from '../teams/TernaryToggle'

export default {
	name: 'Staff',
	mixins: [smallModalMixin, dateTime],
	components: { DraggableSwitch, ContextMenu, Loading, TernaryToggle },
	props: ['staff','selected'],
	data: () => ({ contextMenu: false, working: false }),
	computed: {
		lastLogin() { 
			this.langBackdoor
			return this.staff.lastLogin ? this.date(this.staff.lastLogin) : ''
		},
		joinTeams() {
			const ids = this.staff.teams ? this.staff.teams.map(team=>team.teamId) : []
			return Object.values(this.$store.state.teams.teams).filter(team=>!ids.includes(team.id))
		},
		leaveTeams() {
			return this.staff.teams || []
		},		
		/*
		staffTeam() {
			return this.staff.teams.find(t=>t.teamId===this.team.id)
		}
		*/
	},
	methods: {
		async save(payload) {
			if (
				(payload.closed===false && this.staff.closed && !this.$store.state.profile.organization.teamInvitations && this.$store.state.profile.organization.seats!==null) || 
				(payload.clinician===true && !this.$store.state.profile.organization.teamInvitations && this.$store.state.profile.organization.seats!==null)
			) {
				return this.$store.dispatch('flash/showAlert', 'maxStaff')
			}
			try {
				this.working = true
				await this.$store.dispatch('staff/save', { staffId: this.staff.id, payload })
				if (this.staff.id === this.$store.state.profile.user.id) await this.$store.dispatch('profile/fetch')
				if (payload.closed===true && this.staff.manager) {
					this.showSmallModal(AlertModal, { 
						title: 'Heads up', 
						message: 'This user still has Manager account access at no-charge.<br /><br />Learn more about enabling / disabling Manager account permissions <a href="https://support.simpleset.net/article/78-manage-user-permissions" target="_blank">here...</a>'
					})
				}
			} finally {
				this.working = false			
			}
		},	
		async toggle(prop, val, teamId) {
			const payload = {}
			payload[prop] = val
			try {
				this.working = true		
				await this.$store.dispatch('staff/save', { staffId: this.staff.id, teamId, payload })
				if (this.staff.id === this.$store.state.profile.user.id) await this.$store.dispatch('profile/fetch')
			} finally {
				this.working = false							
			}
		},
		async joinTeam(e) { 
			const teamId = e.target.value
			this.contextMenu = false
			try {
				this.working = true		
				await this.$store.dispatch('staff/joinTeam', { staffId: this.staff.id, teamId })
			} finally {
				this.working = false							
			}			
		},
		async leaveTeam(e) {
			const teamId = e.target.value
			this.contextMenu = false
			try {
				this.working = true		
				await this.$store.dispatch('staff/leaveTeam', { staffId: this.staff.id, teamId })
			} finally {
				this.working = false							
			}					
		}
	}
}
</script>

<style lang='scss'>
.staffManager.singleTeam .staff {

	.info { flex: 4; }

	.userPermissions { 
		flex: 2 !important; 
		> div {
			&:nth-last-child(1), &:nth-last-child(2), &:nth-last-child(3), &:nth-last-child(4) {
				display: none;
			}
		}
	}

}
.staffManager .staff {
	display: flex;
	align-items: stretch;
	position: relative;

	&:hover, &.selected {
		background: $color-focus; 
		.info { background: $color-focus !important; }
	}


	.info {
		position: sticky;
		left: 14px;		
		flex: 3;
		z-index: 2;
		min-width: 250px;
		font-size: $size-font-standard;
		background: #fff;
		display: flex;
		cursor: pointer;
		border-right: 1px solid $color-neutral-shadow;
		padding-top: 6px;
		border-left: 1px solid $color-neutral-shadow;	
		> div:first-child { 
			flex: 0; 
			position: relative;
			.blockButton { transform: scale(0.8); }
			svg { color: $color-neutral-dark; }
		}
		> div:last-child { 
			user-select: all; flex: 1; 
			margin-left: $size-gutter * 2; 
			display: flex;
			align-items: flex-start;
			> div { 
				white-space: nowrap;
				flex: 1; 
			}
		}
		.email { color: $color-primary-accent; font-size: $size-font-small; }
		.name {
			display: flex;
			align-items: flex-end;
		}
		.lastLogin {
			text-align: right;
			font-size: $size-font-small; margin-left: auto; margin-right: $size-gutter * 2; color: $color-neutral-dark;
		}
		.manager {
			text-align: right;
			font-size: $size-font-standard; font-weight: bold; color: $color-success;
			padding-right: $size-gutter * 2;
		}
		.loadingWrapper { 
			position: absolute !important;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #fff;
			.spinner { transform: scale(0.5); }								
		}
	}

	.permissions {
		flex: 12;
		min-height: 40px;
		display: flex;
		align-items: flex-start;
		gap: 1px;
		background: $color-neutral-shadow;

		.noInvitations {
			font-size: $size-font-standard;
			font-style: italic;
			color: $color-neutral-dark;
		}		

		.userPermissions {
			background: $color-neutral-silver;
			display: flex;
			height: 100%;		
			&:hover { background: $color-focus; }	
			> div { 
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 40px;
				cursor: pointer;
			 }
			flex: 6;
		}

		.teamPermissions {
			background: #fff;			
			flex: 8;					
			.team { 
				display: flex;
				align-items: center;
				justify-content: center;
				height: 40px;
				
				.noTeams {
					font-size: $size-font-standard;
					font-style: italic;
					color: $color-neutral-dark;
				}
				
				> div:first-child {
					flex: 2;
					display: flex;
					align-items: center;
					font-size: $size-font-standard;
					overflow: hidden;
					> div {
						display: block;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						padding-left: $size-gutter * 2;
					}
					svg { color: $color-neutral-dark; margin-left: auto; min-width: 24px;}
				}
				> div:not(:first-child) { 
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 40px;
					cursor: pointer;
				 }		
				&:hover { background: $color-focus; }		
			 }
		}		
	}





	.nameLabel {
		cursor: auto;
		svg { color: $color-primary-accent; }
		.label { color: $color-primary-accent; }
		border-bottom: 1px solid $color-neutral-shadow;
	}

	.ternaryLabel .label {
		white-space: wrap;
		display: block;
		span { 
			display: block; white-space: nowrap; 
			&:last-child {
				font-size: $size-font-small;
				color: $color-black;
			}
		}

	}

	.ternaryTitle { display: block; }

	&.disabled {
		.info {
			color: $color-alert;
			.email { color: $color-alert; }
		}
		>div:not(.info):not(.contextMenuWrapper) {
			pointer-events: none;
			> div {
				> * > *:not(.teamTitle) { opacity: 0.5; }
			}
			/*
			.teamPermissions {
				background: #fff !important;
			}
			*/			
		}
		.nameLabel {
			svg { color: $color-alert; }
			.label { color: $color-alert; }
		}		
	}

	.locked {
			pointer-events: none;
			> div { opacity: 0.5; }
			.teamPermissions {
				background: #fff !important;
			}
		.nameLabel {
			svg { color: $color-alert; }
			.label { color: $color-alert; }
		}				
	}

	.changeTeamWrapper { display: flex; &.disabled { pointer-events: none; opacity: 0.5; }}
	.changeTeam {
		height: $size-control-height;
		cursor: pointer;
		text-align: center;
		max-width: 150px;
		margin: 0 auto;
	}

}
</style>