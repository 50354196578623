<template>
<div class='staffManager' :class='{ singleTeam: Object.keys($store.state.teams.teams).length === 1 }'>
	<div class='filters'>
		<div>
			<SearchInput 
				v-model='search'
				@input='debouncedFetch'
				@submit='fetchTop'
				placeholder='Search Users...'
			/>
		</div>
		<div>
			<SearchSelectInput 
				:options='vSelectTeams' 
				:placeholder='$t("views.staffManager.phFilterTeam")'
				v-model='selectedTeam'
			/>
		</div>
		<div>
			<div>(Total: {{total}})</div>
			<div @click='enabled=!enabled'>
				<CheckButton :checked='enabled' />
				<div>Enabled</div>
			</div>
			<div @click='disabled=!disabled'>
				<CheckButton :checked='disabled' />
				<div>Disabled</div>				
			</div>
		</div>		
	</div>
	<div>
		<Scroll ref='scroll' :fetchNext='fetchNext'>
			<div class='staffWrapper' v-if='items.length'>
				<div class='staffHeader'>
					<div class='user'>
						<div>User</div>
					</div>
					<div class='permissions'>
						<div class='teamPermissions'>
							<div class='team'>
								<div>Team</div>
							</div>
							<div class='sharingSection teamSharing'>
								<div><div>Team Sharing</div></div>
								<div>
									<div>Exercises</div>
									<div>Templates</div>
									<div>Education</div>
									<div>Outcomes</div>
									<div>Clients</div>
								</div>
							</div>	
							<div class='toggles'>
								<div>Swap<br />Accounts</div>
							</div>
						</div>
						<div class='userPermissions'>
							<div class='toggles'>
								<div>Client<br />Manager</div>
								<div>2FA</div>
							</div>
							<div class='sharingSection orgSharing'>
								<div><div>
									Organization Sharing
									<svg class='detail' @click='showOrgDetail'>
										<use xlink:href='#detail' />
									</svg>
								</div></div>
								<div>
									<div>Exercises</div>
									<div>Templates</div>
									<div>Education</div>
									<div>Outcomes</div>
								</div>
							</div>
						</div>
					</div>	
				</div>

				<Staff
					v-for='item in items' 
					:key='item' 
					:staff='$store.state.staff.staff[item]' 
					:selected='selected'
					@select='id=>selected=id'
				/>
			</div>
		</Scroll>
	</div>
	<transition name='fade'><Loading v-show='working' /></transition>
</div>
</template>

<script>
import { debounce } from 'lodash'
import { smallModalMixin, largeModalMixin } from '@/components/common/mixins/modal'
import { UI } from '@/utilities'
import AlertModal from '@/components/common/modals/Alert'
import Scroll from '@/components/common/Scroll'
import SearchInput from '@/components/common/SearchInput'
import SearchSelectInput from '@/components/common/SearchSelectInput'
import Loading from '@/components/common/Loading'
import CheckButton from '@/components/common/buttons/Check'
import Staff from './Staff'

export default {
	name: 'Teams',
	mixins: [smallModalMixin, largeModalMixin],
	components: { Scroll, SearchInput, SearchSelectInput, Loading, CheckButton, Staff },
	data() { return {
		working: false,
		search: '',
		enabled: true,
		disabled: true,
		teamId: null,		
		items: [],
		total: 0,
		ui: null,
		selected: null,
		debouncedFetch: debounce(function(search) {
			this.search = search 
			this.fetchTop()
		}.bind(this), 1000, { leading: false, trailing: true })	
	}},
	computed: {
		vSelectTeams() {
			return Object.values(this.$store.state.teams.teams).map(value => ({
				id: value.id,
				label: value.title
			}))
		},
		selectedTeam: {
			get() {
				if (!this.teamId) return null
				return {
					id: this.teamId,
					label: this.$store.state.teams.teams[this.teamId].title
				}
			},
			set(value) {
				this.teamId = value ? value.id : null
			}
		},
		enabledDisabled() {
			return { enabled: this.enabled, disabled: this.disabled }
		}
	},
	methods: {
		async fetchTop() {
			this.debouncedFetch.flush()
			this.working=true
			const [err, result] = await this.$store.dispatch('staff/fetch', { 
				teamId: this.teamId, 
				limit: 50,
				search: this.search,
				sortDir: 'asc',
				...this.enabledDisabled
			})
			this.working=false
			if (!err) {
				this.total = result.total
				this.items = result.items.map(i=>i.id)
			}
		},
		async fetchNext() {
			if (this.items.length===this.total) return
			this.working=true
			const [err, result] = await this.$store.dispatch('staff/fetch', { 
				teamId: this.teamId, 
				skip: this.items.length, 
				limit: 50,
				sortDir: 'asc',				
				search: this.search,
				...this.enabledDisabled
			})
			this.working=false
			if (!err) {
				this.items.push(...result.items.map(i=>i.id))
			}
		},
		showOrgDetail() {
			this.showSmallModal(AlertModal, { 
				title: 'Organization Sharing', 
				message: 'Use these permissions to share Exercises, Templates, Education and Outcomes with ALL Staff on ALL Teams.'
			})
		}
	},
	watch: {
		enabled(v) {
			if (!v && !this.disabled) this.disabled = true
			this.ui.set('staffEnabled', v)
		},
		disabled(v) {
			if (!v && !this.enabled) this.enabled = true
			this.ui.set('staffDisabled', v)				
		},
		async enabledDisabled() { await this.fetchTop() },
		async teamId() { await this.fetchTop() }
	},
	async mounted() {
		this.ui = new UI(this.$store.state.profile.user.id)
		const enabled = this.ui.get('staffEnabled')	
		const disabled = this.ui.get('staffDisabled')
		if (typeof enabled==='boolean') this.enabled = enabled
		if (typeof disabled==='boolean') this.disabled = disabled	
		this.working = true
		await this.$store.dispatch('teams/fetch') 
		await this.fetchTop()
	}
}
</script>

<style lang='scss'>
.staffManager.singleTeam {

	.staffHeader {
		.user { flex: 4; }
		.userPermissions { flex: 2 !important; background: $color-neutral-silver !important; height: 100%; }
		.orgSharing { display: none; }
	}

}

.staffManager {
	@include absTopLeft;
	@include fill;
	display: grid;
	grid-template-rows: auto 1fr;
	background: $color-neutral-panel;

	.filters {
		display: flex; 
		gap: $size-gutter * 2;
		padding: $size-gutter * 2;
        > div { 
            flex: 1;
            &:not(:last-child) { border: 1px solid $color-neutral-shadow; }
            &:last-child {
				display: flex;
				justify-content: center;	
				font-size: $size-font-standard;		
				height: $size-control-height; 
				> div {
					display: flex;
					align-items: center;
					&:first-child { margin-right: $size-gutter * 2; }
					&:last-child { margin-left: $size-gutter * 2; }
					cursor: pointer;
					.checkButton { margin-right: $size-gutter; pointer-events: none; }
				}			
            }
        }
	}

	> div { position: relative; }

	.scrollContainer {
		overflow-x: auto !important;
	}

	.scrollContent, .simplebar-content { min-height: 100% !important; }

	.vs__selected { background: transparent !important; }

	.scrollContent { padding-top: 0; }

	.staffWrapper { 
		margin: 0 $size-gutter * 2; 
		background: #fff;
		border-top: none;
		min-width: 1300px;
		position: relative;
		border-right: 1px solid $color-neutral-shadow;
		border-bottom: 1px solid $color-neutral-shadow;
/*		transform: translate(0,0,0); */
		/*
		&:after {
			position: fixed;
			top: 125px;
			left: 0px;
			width: 14px;
			height: 100%;
			background: $color-neutral-panel;
			content: '';
			z-index: 20;
		}
		*/
	}


	.staffHeader {
		display: flex;
		background: #fff;
		position: sticky;
		z-index: 20;
		top: 0;
		background: #fff;
		border-top: 1px solid $color-neutral-shadow;
		border-bottom: none;

		.user {
			flex: 3;
			min-width: 250px;		
			position: sticky;
			left: 14px;
			background: #fff;
			z-index: 10;
			border-right: 1px solid $color-neutral-shadow;	
			border-left: 1px solid $color-neutral-shadow;						
		}

		.permissions {
			flex: 12;
			display: flex;
			align-items: center;
			background: $color-neutral-shadow;
			gap: 1px;
			.teamPermissions {
				flex: 8;
				display: flex;
				background: #fff;
				.toggles { flex: 1; }
			}
			.userPermissions {
				flex: 6;
				display: flex;
				background: $color-neutral-silver;
				.toggles { flex: 2; }
			}
		}

		.team {
			flex: 2;
		}

		.user, .team {
			display: flex;
			justify-content: center;
			align-items: center;
			color: $color-primary-accent;
			font-weight: bold;
			font-size: $size-font-small;
			> div {
				height: 40px;
				margin-top: auto;
				display: flex;
				align-items: center;
			}
		}

		.sharingSection {


			&.teamSharing { 
				flex: 5; 
				> div:first-child:before {
					left: 10%;
					width: 80%;					
				}
			}
			&.orgSharing { 
				flex: 4; 
				background: $color-neutral-silver;
				> div:first-child:before {
					left: 12.5%;
					width: 75%;					
				}
				.detail { width: 12px; height: 12px; color: $color-primary-accent; margin-left: $size-gutter; cursor: pointer;}
			}

			> div:first-child {

				display: flex;
				align-items: flex-end;
				justify-content: center;
				font-size: $size-font-standard;
				height: $size-control-height;								
				position: relative;
				> div { 
					position: relative; 
					z-index: 3; 
					border: 1px solid $color-neutral-shadow;
					background: $color-neutral-silver;
					height: 24px;
					border-radius: 12px;
					display: flex;
					align-items: center;
					padding: 0 $size-gutter * 2;
					margin-bottom: 1px;
				}
				&:before {
					position: absolute;
					z-index: 1;
					top: calc(50% + 7px);
					border-top: 1px solid $color-neutral-shadow;
					content: ''
				}
			
			}
			> div:last-child {
				display: flex;
				align-items: center;
				justfy-content: center;
				height: $size-control-height;				
				font-size: $size-font-small;
				font-weight: bold;
				color: $color-primary-accent;
				text-align: center;
				> div { 
					flex: 1; 
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;
					height: $size-control-height;				
					&:first-child, &:last-child {
						&:after {
							position: absolute;
							top: -13px;
							left: 50%;
							width: 1px;
							height: 7px;
							border-left: 1px solid $color-neutral-shadow;
							content: ''
						}			
					}		
				}
			}
		}

		.toggles {
			display: flex;
			justify-content: center;
			font-size: $size-font-small;
			font-weight: bold;
			color: $color-primary-accent;
			> div { 
				margin-top: auto;
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				height: $size-control-height;				
			}
		}
	}


}
</style>